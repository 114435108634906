#home {
    position: relative;
    background: url("../../assets/bgIMG.png");
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
 
    .app_wrapper {
       padding: 0;
 
       .copyright {
          display: none;
       }
    }
 }
 
 .app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 6rem 2rem 0;
 
    @media screen and (min-width: 2000px) {
       padding-top: 8rem;
    }
    @media screen and (max-width: 1200px) {
       flex-direction: column;
    }
    @media screen and (max-width: 450px) {
       padding-top: 6rem 1rem 2rem;
    }
 }
 .app__header-info {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
 
    margin: 0 2rem;
 
    @media screen and (max-width: 2000px) {
       width: 100%;
       margin-right: 0rem;
    }
 }
 
 .app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
   //  padding: 130;
 
    .badge-cmp,
    .tag-cmp {
       display: flex;
       padding: 2rem 2rem;
       margin-right: 1rem;
       border: var(--white-color);
       flex-direction: row;
       border-radius: 15px;
       width: auto;
       box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
 
       span {
          font-size: 2rem;
       }
    }
    .tag-cmp {
       flex-direction: column;
       margin-top: 3rem;
 
       p {
          width: 100%;
          text-transform: uppercase;
          text-align: right;
       }
       span {
          font-size: 2.5rem;
 
          @media screen and (max-width: 2000px) {
             font-size: 5rem;
          }
       }
       @media screen and (max-width: 1200px) {
          justify-content: flex-start;
          align-items: flex-start;
       }
    }
 }
 
 .app__header-circles {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
   //  margin-left: 1rem;
    height: 100%;
 
    div {
       width: 100%;
       height: 100%;
       border-radius: 50%;
       background: var(--white-color);
       box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
 
       img {
          width: 70%;
          height: 70%;
       }
    }
 
    div:nth-child(1) {
       width: 100px;
       height: 100px;
    }
    div:nth-child(2) {
       width: 150px;
       margin: 2rem;
       height: 150px;
    }
    div:nth-child(3) {
       width: 70px;
       height: 70px;
    }
 
    @media screen and (min-width: 1200px) {
       div:nth-child(1) {
          width: 120px;
          height: 120px;
       }
       div:nth-child(2) {
          width: 180px;
          height: 180px;
       }
       div:nth-child(3) {
          width: 80px;
          height: 80px;
       }
    }
 
    @media screen and (max-width: 1200px) {
       width: 100%;
       flex-direction: row;
       flex-wrap: wrap;
       margin-left: 0;
 
       div {
          margin: 1rem;
       }
    }
 }
 
 .app__header-img {
    flex: 3;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
 
    img {
       width: 100%;
       object-fit: contain;
       z-index: 1;
    }
    .overlay_circle {
       position: absolute;
       left: 0;
       right: 0;
       bottom: 0;
       z-index: 0;
 
       width: 100%;
       height: 90%;
    }
 
    @media screen and (min-width: 768px) {
       margin: 2rem 0;
    }
 }
 